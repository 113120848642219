.documents {
  display: flex;
}

.documents > div {
  border-radius: 15px;
  border: 1px solid grey;
  flex: 1;
  margin: 10px 10px;
  padding: 10px;
}

.multiselect-container {
  border: 1px solid rgb(209 213 219);
  margin-top: -14px;
  border-radius: 6px;
  height: 42px;
}
.search-wrapper {
  border: 0px;
}

.permissionsClass .searchWrapper {
  height: 50px;
  overflow: scroll;
  border: 1px solid rgb(209 213 219);
  border-radius: 6px;
  padding: 10px;
}

.multiselect-container .search-wrapper:has(span) {
  height: 60px;
}

.permissionsClass {
  border: 0px;
}
.permissionsClass .searchWrapper::-webkit-scrollbar {
  display: none;
}

.tox .tox-editor-container {
  z-index: 0;
}

.tox-notifications-container {
  visibility: hidden;
}

.pagination-viewer {
  display: flex;
}
.pagination-button {
  border: none;
  background-color: #bd1d1d;
  color: white;
  border-radius: 10px;
  padding: 8px 10px;
  margin-right: 10px;
}

.progress-bar-tooltip {
  position: relative;
  display: inline-block;
}
.progress-bar-tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  font-size: 12px;
  background-color: rgb(90, 90, 90);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 90%;
}
.progress-bar-tooltip:hover .tooltiptext {
  visibility: visible;
}

.active {
  --tw-bg-opacity: 1;
  color: rgb(14 165 233 / var(--tw-bg-opacity));
  text-decoration: underline;
}

.active-step {
  background-color: #0081c9 !important;
}

body {
  background-color: #f5f5f5;
}

.identifiers {
  color: #bab9b5;
  font-size: 16px;
}

.identifiers span {
  color: #000000;
}

.editor-margin {
  margin-left: 35px !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: absolute;
  top: 30%;
  left: 53%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 40rem;
  max-width: 50rem;
  padding: 10px;
}

.content-box-sizing *,
.content-box-sizing *::before,
.content-box-sizing *::after {
  box-sizing: content-box;
}
