@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.019px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:not(:root):fullscreen::backdrop {
  background-color: white;
}
